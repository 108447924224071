import './App.css'
import React from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyAgjGEC1E8N3AQkhQqCk85frBBkRgJlqbo",
	authDomain: "belforti-landing.firebaseapp.com",
	projectId: "belforti-landing",
	storageBucket: "belforti-landing.appspot.com",
	messagingSenderId: "275354042469",
	appId: "1:275354042469:web:70e2f0eec13ba9d333068c",
	measurementId: "G-ZH3MCEE1ZB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

function App() {
	return (
		<div className='main' style={{ textAlign: 'center' }}>
			{/* <header> */}
			<p className='font-face-gm'>
				Belforti. <br />
			</p>
			<p className='font-face-gm phone-restrict'>
				Confezione Italiana
			</p>
			{/* </header> */}
			<a href='mailto:info@belforteitalia.com'  onClick={() => window.location = 'mailto:info@belforteitalia.com'} className='font-face-gm phone-restrict-lot text-bottom' style={{ textTransform: 'none' }}>
				Contatti: info@belforteitalia.com
			</a>
		</div>
	);
}

export default App;
